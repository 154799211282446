import React, { Dispatch, SetStateAction } from 'react';

import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import ControlGroup from '@amzn/meridian/control-group';
import Heading from '@amzn/meridian/heading';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import RadioButton from '@amzn/meridian/radio-button';
import Row from '@amzn/meridian/row';

import { ListDetails, ListFlag, ListState } from '../../../listoramaAdmin-api/generated-src';
import { SelectedLists } from '../../features/UserDetailsSection/components/ListsDataTable/UserListsDataTable';
import { ActionButtonProps } from '../ListActionButtons/ListActionButtons';

type ButtonId = 'edit_list_state' | 'add_list_flag' | 'remove_list_flag';

export interface ModalData {
    isOpen: boolean;
    buttonProps?: ActionButtonProps<ButtonId>;
}

export interface ListEditData {
    state?: ListState;
    flag?: ListFlag;
}

interface ListActionModalProps {
    modalData: ModalData;
    setModalData: Dispatch<SetStateAction<ModalData>>;
    listEditData: ListEditData;
    setListEditData: Dispatch<SetStateAction<ListEditData>>;
    list?: ListDetails;
    selectedLists?: SelectedLists;
}

export const ListActionModal: React.FC<ListActionModalProps> = (props: ListActionModalProps) => {
    const { list, selectedLists, modalData, setModalData, listEditData, setListEditData } = props;

    const onModalClose = () => {
        setModalData({ isOpen: false });
        setListEditData((prevState) => ({ ...prevState, flag: undefined }));
    };

    return (
        <Modal width={400} onClose={onModalClose} title={modalData.buttonProps?.text} open={modalData.isOpen}>
            {modalData.buttonProps ? (
                createModalContent(list, selectedLists, modalData.buttonProps, listEditData, setListEditData)
            ) : (
                <></>
            )}
        </Modal>
    );
};

interface FlagSelectInputProps {
    list: ListDetails | undefined;
    buttonId: ButtonId;
    listEditData: ListEditData;
    setListEditData: Dispatch<SetStateAction<ListEditData>>;
}

const FlagSelectInput = (props: FlagSelectInputProps) => {
    const { list, buttonId, listEditData, setListEditData } = props;

    const isFlagInDropdown = (flag: ListFlag) => {
        switch (buttonId) {
            case 'add_list_flag':
                return list && list.flags.indexOf(flag) < 0;
            case 'remove_list_flag':
                return list && list.flags.indexOf(flag) >= 0;
        }
    };

    const options = Object.keys(ListFlag)
        .filter((key) => (list ? isFlagInDropdown(ListFlag[key]) : true))
        .map((key) => ({ key, value: ListFlag[key], label: ListFlag[key] }));
    return (
        <SelectInput
            id={buttonId}
            placeholder='Select flag'
            value={listEditData.flag!}
            onChange={(flag) => setListEditData((prevState) => ({ ...prevState, flag }))}
            options={options}
        />
    );
};

const createInput = (
    list: ListDetails | undefined,
    buttonProps: ActionButtonProps<ButtonId>,
    listEditData: ListEditData,
    setListEditData: Dispatch<SetStateAction<ListEditData>>
): React.ReactNode => {
    switch (buttonProps.id) {
        case 'edit_list_state':
            return (
                <ControlGroup
                    legend='Select state'
                    value={listEditData.state}
                    onChange={(state) => setListEditData((prevState) => ({ ...prevState, state }))}
                >
                    {Object.keys(ListState).map((key) => (
                        <RadioButton key={key} value={ListState[key]}>
                            {ListState[key]}
                        </RadioButton>
                    ))}
                </ControlGroup>
            );
        case 'add_list_flag':
        case 'remove_list_flag':
            return (
                <FlagSelectInput
                    list={list}
                    buttonId={buttonProps.id}
                    listEditData={listEditData}
                    setListEditData={setListEditData}
                />
            );
    }
};

const createModalContent = (
    list: ListDetails | undefined,
    selectedLists: SelectedLists | undefined,
    buttonProps: ActionButtonProps<ButtonId>,
    listEditData: ListEditData,
    setListEditData: Dispatch<SetStateAction<ListEditData>>
): React.ReactNode => (
    <Column spacing='200'>
        {selectedLists && <Heading level={4}>{createSelectedListsMessage(selectedLists)}</Heading>}
        {createInput(list, buttonProps, listEditData, setListEditData)}
        <ModalFooter>
            <Row alignmentHorizontal='end' widths='fit'>
                <Button type='primary' size='small' onClick={() => buttonProps.onConfirm(listEditData)}>
                    Confirm
                </Button>
            </Row>
        </ModalFooter>
    </Column>
);

const createSelectedListsMessage = (selectedLists: SelectedLists) => {
    const lists = Object.keys(selectedLists).filter((listId) => selectedLists[listId]);
    return lists.length > 1 ? `Editing ${lists.length} lists...` : `Editing ${lists.length} list...`;
};
