import React from 'react';

import { CustomRowTableCellProps, Header } from '@amzn/imdb-shared-meridian-components/components/DataTable';

import { ListItemDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { ListDataTable } from '../../../../components/ListDataTable/ListDataTable';
import { ItemText, ListItemsText } from '../../queries/GetListItemsTextQuery';

export type ColumnId = 'position' | 'theConst' | 'itemText' | 'originalDescription' | 'modified';

interface ListItemsDataTableProps {
    listItems: ListItemDetails[];
    listItemsText: ListItemsText;
}

export const ListItemsDataTable: React.FC<ListItemsDataTableProps> = (props: ListItemsDataTableProps) => {
    const { listItems, listItemsText } = props;
    listItems.map((li) => addListItemText(li, listItemsText as ItemText[]));

    return (
        <ListDataTable
            headers={createHeaders()}
            rows={listItems.map((li) => addListItemText(li, listItemsText as ItemText[]))}
            showPaginationOptions={true}
            customRowTableCell={CustomRowTableCell}
            emptyTableText={'List is empty.'}
        />
    );
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<string>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'originalDescription':
            return (
                <DefaultTableCellContent
                    data={row.originalDescription ? row.originalDescription.toString().substring(0, 100) : ''}
                />
            );
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const addListItemText = (li: ListItemDetails, listItemsText: ItemText[]): any => {
    const itemText = listItemsText.find((liText) => liText.id === li.theConst);
    li.itemText = itemText ? getListItemText(itemText) : 'N/A';
    return li;
};

const getListItemText = (itemText: ItemText): string =>
    itemText.seriesInfo
        ? `${itemText.seriesInfo.parentTitle.text.value}: ${itemText.text.value}`
        : itemText.text?.value || itemText.text || 'N/A - Invalid Const';

const createHeaders = (): Header<ColumnId>[] => [
    {
        id: 'position',
        label: 'Position'
    },
    {
        id: 'theConst',
        label: 'Const'
    },
    {
        id: 'itemText',
        label: 'Item'
    },
    {
        id: 'originalDescription',
        label: 'Abbreviated Item Description'
    },
    {
        id: 'modified',
        label: 'Last Modified'
    }
];
