import React, { useState } from 'react';

import {
    CustomRowTableCellProps,
    DataTable,
    Header,
    Row,
    SelectableOptions
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Pagination from '@amzn/meridian/pagination';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';
import Text from '@amzn/meridian/text';

interface ListDataTableProps {
    headers: Header<string>[];
    rows: Row<string>[];
    itemsPerPageOptions?: number[];
    showPaginationOptions?: boolean;
    customRowTableCell?: React.VFC<CustomRowTableCellProps<string>>;
    emptyTableText?: string;
    selectableOptions?: SelectableOptions;
}

export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [15, 50, 100, 250];

export const ListDataTable: React.FC<ListDataTableProps> = (props: ListDataTableProps) => {
    const { headers, rows, customRowTableCell, selectableOptions } = props;

    const itemsPerPageOptions = props.itemsPerPageOptions ?? DEFAULT_ITEMS_PER_PAGE_OPTIONS;
    const showPaginationOptions = props.showPaginationOptions ?? false;
    const emptyTableText = props.emptyTableText ?? 'Empty';

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(itemsPerPageOptions[0]);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = rows.length < itemsPerPage ? rows.length : firstVisibleIndex + itemsPerPage;
    const numberOfPages = Math.ceil(rows.length / itemsPerPage);
    const lastVisibleList = Math.min(lastVisibleIndex, rows.length);

    const onItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    const createActionBarProps = (itemsPerPage: number): TableActionBarProps => {
        const options = itemsPerPageOptions.map((option) => {
            const label = `${option} items per page`;
            return {
                option,
                value: option,
                label: label
            };
        });

        return {
            children: (
                <SelectInput
                    label='Items per page'
                    id='items_per_page'
                    value={itemsPerPage}
                    onChange={onItemsPerPageChange}
                    options={options}
                />
            )
        };
    };

    return rows.length > 0 ? (
        <Column width='100%' data-test-id='listsTable'>
            {showPaginationOptions && (
                <Heading level={5}>{`Showing entries ${firstVisibleIndex + 1} to ${lastVisibleList} of ${
                    rows.length
                } total`}</Heading>
            )}
            <DataTable
                data={{ headers: headers, rows: rows.slice(firstVisibleIndex, lastVisibleIndex) }}
                selectableOptions={
                    selectableOptions && {
                        idFieldName: selectableOptions.idFieldName,
                        selected: selectableOptions.selected,
                        setSelected: selectableOptions.setSelected
                    }
                }
                actionBarProps={showPaginationOptions ? createActionBarProps(itemsPerPage) : undefined}
                CustomRowTableCell={customRowTableCell}
            />
            {showPaginationOptions && (
                <Pagination
                    showSkipArrows={true}
                    numberOfPages={numberOfPages}
                    onChange={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </Column>
    ) : (
        <Text type='h300'>{emptyTableText}</Text>
    );
};
