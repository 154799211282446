import { Feature } from '../constants';

export interface LinkData {
    text: string;
    id: Feature;
    link: string;
}

const HOME: LinkData = {
    text: 'Home',
    id: 'Home',
    link: '/'
};

export const LIST_DETAILS: LinkData = {
    text: 'List Details',
    id: 'ListDetails',
    link: '/listDetails'
};

export const USER_DETAILS: LinkData = {
    text: 'User Details',
    id: 'UserDetails',
    link: '/userDetails'
};

export const BULK_WORKFLOW: LinkData = {
    text: 'Bulk Workflow',
    id: 'BulkWorkflow',
    link: '/bulkWorkflow'
};

export const FEATURE_LINKS: LinkData[] = [HOME, BULK_WORKFLOW];
