import React, { Dispatch, SetStateAction } from 'react';

import Button from '@amzn/meridian/button';
import { ButtonProps } from '@amzn/meridian/button/button';
import Row from '@amzn/meridian/row';

import { ListDetails } from '../../../listoramaAdmin-api/generated-src';
import { SelectedLists } from '../../features/UserDetailsSection/components/ListsDataTable/UserListsDataTable';
import { ListEditData, ListActionModal, ModalData } from '../ListActionModal/ListActionModal';

export type ButtonId = 'edit_list_state' | 'add_list_flag' | 'remove_list_flag';

export interface ActionButtonProps<T extends string> extends Omit<ButtonProps, 'children'> {
    id: T;
    text: string;
    onConfirm: (data: ListEditData) => any;
}

interface ListActionButtonsProps {
    buttons: ActionButtonProps<ButtonId>[];
    listEditData: ListEditData;
    setListEditData: Dispatch<SetStateAction<ListEditData>>;
    modalData: ModalData;
    setModalData: Dispatch<SetStateAction<ModalData>>;
    list?: ListDetails;
    selectedLists?: SelectedLists;
}

export const ListActionButtons: React.FC<ListActionButtonsProps> = (props: ListActionButtonsProps) => {
    const { buttons, list, selectedLists, listEditData, setListEditData, modalData, setModalData } = props;

    const setModalOpen = (buttonProps: ActionButtonProps<ButtonId>) => {
        setModalData({ isOpen: true, buttonProps });
    };

    return (
        <Row>
            {buttons.map((button) => (
                <Button onClick={() => setModalOpen(button)} key={button.id} {...button}>
                    {button.text}
                </Button>
            ))}
            <ListActionModal
                list={list}
                modalData={modalData}
                setModalData={setModalData}
                listEditData={listEditData}
                setListEditData={setListEditData}
                selectedLists={selectedLists}
            />
        </Row>
    );
};
