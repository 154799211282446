import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { WorkflowType } from '../../../listoramaAdmin-api/generated-src';
import { BulkWorkflowFormView } from './BulkWorkflowFormView';

interface BulkUpdateUserStatusFormInput {
    inputLines?: string[];
    workflowType?: WorkflowType;
    workflowContext?: string;
}

interface BulkUpdateUserStatusResponseData {
    fetching?: boolean;
    error?: AxiosError;
}

export const BulkWorkflowForm: React.VFC = () => {
    const [inputData, setInputData] = useState<BulkUpdateUserStatusFormInput>({});
    const [responseData, setResponseData] = useState<BulkUpdateUserStatusResponseData>({ fetching: false });
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const validateInput = (input: BulkUpdateUserStatusFormInput) => {
        if (!input?.inputLines || input.inputLines.length === 0) {
            setResponseData({ error: new Error('Please ensure you have entered all inputs.') as AxiosError });
            return;
        }

        if (!input?.workflowType) {
            setResponseData({ error: new Error('Please ensure you have selected a workflow') as AxiosError });
            return;
        }

        setResponseData({ error: undefined });
        setIsModalOpen(true);
    };

    const onInputChange = (newData: object) => {
        setInputData({ ...inputData, ...newData });
    };

    const onSubmit = async () => {
        setIsModalOpen(false);
        return;
    };

    return (
        <BulkWorkflowFormView
            inputData={inputData}
            onInputChange={onInputChange}
            responseData={responseData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSubmit={onSubmit}
            validateInput={validateInput.bind(this, inputData)}
        />
    );
};
